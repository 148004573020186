"use strict";

(function () {
  window.StCheckButton = {
    props: {
      value: Boolean,
      indeterminate: Boolean,
      version: {
        type: String,
        "default": 'v1'
      }
    },
    computed: {
      getPrimaryClass: function getPrimaryClass() {
        if (this.version === 'v1') return 'st-check-button';
        return 'st-check-button v2';
      },
      getMarkClass: function getMarkClass() {
        if (this.indeterminate) return 'indeterminatemark';
        return 'checkmark';
      }
    },
    template: "\n\t\t\t<label :class=\"getPrimaryClass\">\n\n\t\t\t\t<input type=\"checkbox\"\n\t\t\t\t    class=\"st-check-button__button\"\n\t\t\t\t    @input=\"$emit('input', $event.target.checked)\"\n\t\t\t\t    @change=\"$emit('change', $event.target.checked)\"\n\t\t\t\t    :checked=\"value\"\n\t\t\t\t    :indeterminate=\"indeterminate\">\n\n\t\t\t  <span :class=\"getMarkClass\"></span>\n\n\t\t\t</label>\n\t\t"
  };
})();